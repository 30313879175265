@media (max-width: 1024px) {
  .lg\\:hidden {
    display: none;
  }
  .lg\\:block {
    display: block;
  }
}

@media (min-width: 1025px) {
  .lg\\:hidden {
    display: block;
  }
  .lg\\:flex {
    display: none;
  }
}
