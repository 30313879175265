body {
  margin: 0;
  padding: 0;
  font-family: Outfit, sans-serif, Poppins; /* Added fallback font */
}

* {
  box-sizing: border-box;
  line-height: normal;
  font-family: inherit;
  margin: unset; /* Consistencia en márgenes */
}

/* Class for stats column with border */


.statscolumn_border {
  backdrop-filter:blur(1px);
  &::before {
  content: "";
  position: absolute;
  inset: 0;
  background: linear-gradient(162deg, #8bff8e, #403886, #0f0d39, #2dd98f63);
  background-clip: border-box;
  mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
  mask-composite: exclude !important;
  z-index: -10;
  border-radius: 20px;
  border: 1px solid transparent;
  }
}


.tetherrow_border {
  backdrop-filter: blur(1px);
  &::before {
  content: "";
  position: absolute;
  inset: 0;
  background: linear-gradient(177deg, #8b6eff, #0d0b33, #2dd98f);
  background-clip: border-box;
  mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
  mask-composite: exclude !important;
  z-index: -10;
  border-radius: 10px;
  border: 1px solid transparent;
  }
}

/* Class for stack view border */


.stackview_border {
  backdrop-filter: blur(1px);
  &::before {
  content: "";
  position: absolute;
  inset: 0;
  background: linear-gradient(333deg, #677fd2, #2f3b8d);
  background-clip: border-box;
  mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
  mask-composite: exclude !important;
  z-index: -10;
  border-radius: 20px;
  border: 1px solid transparent;
  }
}



.imagecolumn_border {
  backdrop-filter: blur(1px);
  &::before{
  content: "";
  position: absolute;
  inset: 0;
  background: linear-gradient(162deg, #8bffb5, #403886, #0f0d39, #2dd98f9b); /* Corrected colors and formatting */
  background-clip: border-box;
  mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
  mask-composite: exclude !important;
  z-index: -10;
  border-radius: 80px;
  border: 1px solid transparent;
  }
}

a {

  display: block;
}

ul {
  margin: 0;
  margin-inline:unset !important;
  padding: 0;
  list-style: none;
}

input {
  background-color: transparent;
  width: 100%;
  
  
}

input::placeholder{
 color: #c0c0c0;
}

input:focus {
  outline: 2px solid #4A90E2; /* Indicador visual del enfoque */
  outline-offset: 2px;
  
}

table {
  table-layout: fixed;
}

/* InfiniteSlider.css */
.slider-container {
  width: 80%;
  margin: auto;
  
}

.slide-image {
  width: 50%;
  height: auto;
  
}


.text-gradient {
  background: linear-gradient(220deg, #5c8fff, #c14bff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.border-color {
  background: linear-gradient(220deg, #5c8fff, #c14bff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

