@font-face {
    font-family: "Poppins";
    src: url("/public/fonts/PoppinsSemiBold.ttf");
    font-weight: 600;
}
@font-face {
    font-family: "Poppins";
    src: url("/public/fonts/PoppinsSemiBold.ttf");
}
@font-face {
    font-family: "Poppins";
    src: url("/public/fonts/PoppinsRegular.ttf");
}
