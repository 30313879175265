/* Ajustes adicionales para Tailwind CSS */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Reset de formularios */
[type="text"],
input:where(:not([type])),
[type="email"],
[type="url"],
[type="password"],
[type="number"],
[type="date"],
[type="datetime-local"],
[type="month"],
[type="search"],
[type="tel"],
[type="time"],
[type="week"],
[multiple],
textarea,
select {
  appearance: none;
  background-color: transparent;
  border-color: unset;
  border-width: 0;
  border-radius: unset;
  padding: 0; /* Ajuste para evitar padding inesperado */
  font-size: 1rem; /* Ajuste opcional para una mejor legibilidad */
  line-height: 1.5; /* Ajuste opcional para una mejor legibilidad */
}


/* Override default focus colors for tailwindcss-forms */
[type="text"]:focus,
[type="email"]:focus,
[type="url"]:focus,
[type="password"]:focus,
[type="number"]:focus,
[type="date"]:focus,
[type="search"]:focus,
[multiple]:focus,
textarea:focus,
select:focus {
  --tw-ring-color: transparent;
  border-color: transparent;
}

/* Estilo para el contenedor */
.container {
  max-width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
}

@media (min-width: 640px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1140px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1440px;
  }
}

/* Utilidades personalizadas */
@layer utilities {
  .custom-background {
    background-image: url('/Images/HomePag-img/Body.png');
    background-size: cover;
    background-position: center;
  }
}

/* Ajuste específico para pantallas pequeñas */
@media (max-width: 640px) {
  .container {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}



@layer base {
  :root {
    --accents_blue: #2774ff;
    --accents_fuchsia: #dd50d5;
    --accents_green: #50d1b2;
    --accents_pink: #fa7bb8;
    --accents_violet: #7747c9;
    --black_900: #07070c;
    --black_900_01: #000000;
    --black_900_19: #00000019;
    --black_900_1: #0000001e;
    --black_900_3f: #0000003f;
    --blue_a400_26: #2774ff26;
    --blue_gray_50_99: #e8edf299;
    --brand_brand_1: #7364da;
    --deep_orange_300: #eb8b55;
    --deep_purple_400_26: #7747c926;
    --gray_300: #e2e2ea;
    --indigo_500: #25bbed;
    --neutral_neutral_0__day_: #77e8fd;
    --neutral_neutral_100__day_: #f5f5fa;
    --neutral_neutral_1100___day_: #07070b;
    --neutral_neutral_300__day_: #c5cad8;
    --neutral_neutral_300__night_: #2c2c35af;
    --neutral_neutral_400__day_: #9a9aaf;
    --neutral_neutral_400__night_: #64646e;
    --neutral_neutral_500__day_: #7d7d8f;
    --neutral_neutral_bg___day_: #ffffff;
    --neutral_neutral_border__day_: #e8edf2;
    --purple_700: #78319d;
    --teal_300_26:#50d1b226;
    --teal_300_33: #50d1b233;
    --teal_400: #2b9b80;


    --shadow-xs: 0 40px 120px 0 #0000001e;
    --shadow-sm: 0 4px 8px 0 #00000019;

    --radius-xs: 4px;
    --radius-sm: 8px;
    --radius-md: 10px;
    --radius-lg: 12px;
    --radius-xl: 16px;
    --radius-2xl: 24px;

    --space-xs: 2px;
    --space-sm: 4px;
    --space-md: 6px;
    --space-lg: 8px;
    --space-xl: 10px;
    --space-2xl: 12px;
    --space-3xl: 14px;
    --space-4xl: 16px;
    --space-5xl: 18px;
    --space-6xl: 20px;
    --space-7xl: 22px;
    --space-8xl: 24px;
    --space-9xl: 26px;
    --space-10xl: 30px;
    --space-11xl: 32px;
    --space-12xl: 34px;
  }
}