.button-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 10px;
}

.custom-button {
    width: 100%;
    padding: 5px;
    font-size: 14px;
    margin: 1px;
    color: white;
    background-size: cover;
    background-position: center;

    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    text-shadow: 1px;
    
}

.custom-button:hover {
    opacity: 0.9;
}